(function($) {

  function Header(element) {
    this.$el = $(element);
    this.$window = $(window);
    this.IS_FIXED = 'is-fixed';
  }

  Header.prototype = {
    init: function() {
      var t = this;
      t.$window.scroll(function(){
      var scroll = $(window).scrollTop();

      if (scroll >= 1){
        t.$el.addClass(t.IS_FIXED);
      }else {
        t.$el.removeClass(t.IS_FIXED);
      }
    });
    }
  };

  if ($('.js-header').length) {
    var header = new Header('.js-header');
    header.init();
  }
})(jQuery);
